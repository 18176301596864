import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ContentCard from '../components/Layout/ContentCard'
import Layout from '../components/Layout/Layout'
import SEO from "../components/seo"

export const query = graphql`
  query {
    allTaxonomyTermBrand(sort: {order: ASC, fields: name}) {
      edges {
        node {
          id
          drupal_internal__tid
          title: name
          path {
            alias
          }
          field_website {
            uri
          }
          field_teaser
          field_address {
            city: locality
            state: administrative_area
          }
          relationships {
            field_image: field_logo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 150, maxHeight: 150, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            featuredImage: field_logo {
              localFile {
                childImageSharp {
                  fixed(width: 100, height: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

const BrandPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <section className="container mx-auto px-5">
      <div className="page-title">Made in USA Bicycle Products</div>
      {data.allTaxonomyTermBrand.edges.map(brand => (
      <>
        <ContentCard data={brand.node} location={location} />
      </>
      ))}
    </section>
  </Layout>
)

BrandPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default BrandPage;